<template>
  <el-container class="wx-app-page">
    <el-aside
      class="app-list-con"
      v-loading="loading"
      element-loading-text="拼命加载中..."
      element-loading-spinner="el-icon-loading"
    >
      <div class="tool-bar light-shadow">
        <div class="title">微信应用列表</div>
        <el-button
          v-power="`40-10-10`"
          icon="el-icon-plus"
          type="primary"
          size="mini"
          @click="doAddWxApp"
          >新增</el-button
        >
      </div>

      <wx-app-list ref="appList" @select="selectApp" @loaded="appListLoaded" />
    </el-aside>
    <el-main style="padding: 1rem">
      <wx-app-detail
        ref="wxAppDetail"
        :app-config-id="selectedAppId"
        @changed="appChanged"
      />
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: "wx-apps",
  data() {
    return {
      loading: true,
      selectedAppId: null,
    };
  },
  methods: {
    doAddWxApp() {
      this.$refs.wxAppDetail.doAdd();
      this.$refs.appList.unselect();
    },
    appListLoaded() {
      this.loading = false;
    },
    appChanged() {
      this.loading = true;
      this.$refs.appList.refresh();
    },
    selectApp(appId) {
      this.selectedAppId = appId;
    },
  },
  components: {
    "wx-app-list": () => import("./WxAppList"),
    "wx-app-detail": () => import("./WxAppDetail"),
  },
};
</script>

<style lang="less">
.wx-app-page {

  .app-list-con {
    border-right: 1px #cccccc solid;

    .tool-bar {
      height: 2.5rem;
      border-bottom: 1px #cccccc solid;
      text-align: right;
      line-height: 2.5rem;
      padding: 0px 3px;
      background-color: #c9c9c9;

      .title {
        float: left;
        font-weight: 600;
      }
    }
  }
}
</style>